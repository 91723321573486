import haversine from 'haversine-distance';

/**
 * 두 지점 간의 거리를 계산하는 함수
 * @param {Object} point1 - 첫 번째 지점 (허브 위치)
 * @param {number} point1.lat - 첫 번째 지점의 위도
 * @param {number} point1.lon - 첫 번째 지점의 경도
 * @param {Object} point2 - 두 번째 지점 (응답 위치)
 * @param {number} point2.lat - 두 번째 지점의 위도
 * @param {number} point2.lon - 두 번째 지점의 경도
 * @returns {number} 두 지점 간의 거리 (미터 단위)
 */
const calculateDistance = (point1, point2) => {
    const distance = haversine(point1, point2); // meters
    console.log(`point1: ${JSON.stringify(point1)}  point2 : ${JSON.stringify(point2)}`);
    console.log(`Distance: ${distance} meters`);
    return distance;
};

const removeAllSpaces = (str) => {
    return str.replace(/\s+/g, '');
};

export { calculateDistance, removeAllSpaces }