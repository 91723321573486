import { IconButton, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '../images/ic-search.svg';
import MapOutlinedIcon from '../images/ic-location-on.svg';
import ClearIcon from '../images/ic-close-circle-fill.svg';

import useStore from '../store';
import React, { forwardRef } from 'react';

const SearchBar = forwardRef(({ keyword, setKeyword, handleClear, handleMapClick, isKeywordEdited }, ref) => {
  const { request } = useStore();
  return (
    <TextField
      fullWidth
      variant="outlined"
      value={keyword}
      inputRef={ref} // ref 전달
      onChange={(e) => setKeyword(e.target.value, true)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          // e.preventDefault(); // Enter 기본 동작 막기
          console.log('enter keyword : ', keyword);
          setKeyword(keyword, true); // Enter 입력 시에도 isKeywordEdited 설정
        }
      }}
      placeholder={request === "favorite" || request === "modify" ? "즐겨 찾는 장소 검색" : "출발지 검색"}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <img alt='찾기' src={SearchIcon} style={{ width: '18px', height: '18px' }}/>
            </IconButton>

          </InputAdornment>
        ),
        endAdornment: (
          <>
            {keyword && (
              <InputAdornment position="end">
                <IconButton onClick={handleClear} edge="end">
                  <img src={ClearIcon} alt='삭제' style={{ width: '24px', height: '24px' }}/>
                </IconButton>
              </InputAdornment>
            )}
            {((request === 'modify' && !isKeywordEdited) || !keyword) && <InputAdornment position="end">
              <IconButton onClick={handleMapClick} edge="end">
                <img src={MapOutlinedIcon} alt='지도' style={{ width: '24px', height: '24px' }}/>
              </IconButton>
            </InputAdornment>}
          </>
        ),
      }}
      sx={{
        backgroundColor: 'white',  // 입력 필드 배경색을 흰색으로 설정
        borderRadius: '10px',  // 필드를 둥글게 만듦
        '& .MuiOutlinedInput-root': {
          borderRadius: '10px',  // 입력 필드 둥근 모서리 설정
          '& fieldset': {
            borderRadius: '10px',  // 필드셋에 둥근 모서리 적용
            borderColor: 'transparent',  // 테두리 색상 설정
          },
          '&:hover fieldset': {
            borderColor: '#5266f8',  // hover 시 테두리 색상 유지
          },
          '&.Mui-focused fieldset': {
            borderColor: '#5266f8',  // focus 시 테두리 색상 유지
          },
        },
        '& input': {
          padding: '12px 0px',  // 입력창 안쪽 패딩 조정
          height: '26px',  // 입력창 높이 조정
          backgroundColor: 'white',  // 글씨 입력 시 배경색을 흰색으로 고정
        },
        // Chrome 자동 완성 스타일 제거
        '& input:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 1000px white inset !important',  // 자동 완성 배경색 덮어쓰기
          backgroundColor: 'white !important',
          color: '#000 !important',  // 자동 완성된 텍스트 색상을 검정색으로 설정
        },
        '& input:-webkit-autofill:focus': {
          WebkitBoxShadow: '0 0 0 1000px white inset !important',
          backgroundColor: 'white !important',
        },
        '& input:-webkit-autofill:hover': {
          WebkitBoxShadow: '0 0 0 1000px white inset !important',
          backgroundColor: 'white !important',
        },
        '& input:-webkit-autofill:active': {
          WebkitBoxShadow: '0 0 0 1000px white inset !important',
          backgroundColor: 'white !important',
        }
      }}
      style={{ marginBottom: '1rem', marginTop: '1rem' }}
    />
  );
});

export default SearchBar;