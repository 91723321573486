import React from 'react';

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // 정규식 특수문자 이스케이프
};

const HighlightedText = ({ text, keyword }) => {
  if (!keyword) return <>{text}</>; // 키워드가 없을 경우, 기본 텍스트 반환

  const escapedKeyword = escapeRegExp(keyword); // 특수문자 이스케이프 처리
  // keyword 기준으로 텍스트를 나누고, 그 부분에 스타일 적용
  const parts = text.split(new RegExp(`(${escapedKeyword})`, 'gi')); // 대소문자 구분없이 나누기

  return (
    <>
      {parts.map((part, index) => (
        part.toLowerCase() === keyword.toLowerCase() ? (
          <span key={index} style={{ color: '#4a57bf' }}>{part}</span>
        ) : (
          <span key={index}>{part}</span>
        )
      ))}
    </>
  );
};

export default HighlightedText;