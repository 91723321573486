import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, TextField, Button, Box, Container, InputAdornment, IconButton, } from '@mui/material';
import BackButton from './components/BackButton';
import CustomDialog from './components/CustomDialog';
import ClearIcon from './images/ic-close-circle-fill.svg';
import AndroidToast, { showAndroidToast } from './components/Toast';
import { isAndroid, isIOS } from 'react-device-detect';
import { removeAllSpaces } from './Utils';

import CrossFrame from "./crossFrame";
import useStore from './store';

const InputName = ({ crossFrameInstance }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // 입력된 값을 관리할 상태 선언
  console.log('location.state : ', location.state);
  const [inputValue, setInputValue] = useState('');
  const [warningText, setWarningText] = useState('');
  const { favorites, modifyId } = useStore();
  const [dialogProps, setDialogProps] = useState({ open: false, title: '', content: '', confirmText: '' });
  const inputRef = useRef(null);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(true);

  const [addressName, setAddressName] = useState(location.state.addressName || '');
  const [originalAddressName, setOriginalAddressName] = useState(location.state.originalAddressName || '');
  
  const instanceRef = useRef({crossFrame: null});

  const onCrossFrameInitial = useCallback(() => {
    const instance = instanceRef.current;
    instance.crossFrame?.destroy();

    instance.crossFrame = null;
    instance.crossFrame = new CrossFrame();
  }, []);

  useEffect(() => {
    console.log('InputName useEffect');
    onCrossFrameInitial();
  }, [onCrossFrameInitial]);

  useEffect(() => {
    console.log('init...');
    // 컴포넌트가 마운트되면 input에 포커스를 자동으로 설정
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const inputElement = inputRef.current;
    const handleFocusIn = () => {
      setIsKeyboardVisible(true);
    };
  
    const handleFocusOut = () => {
      //딜레이를 안주면 버튼이 키보드때문에 올라와있을때 터치를 하면 키보드가 순식간에 내려가면서 터치하는 위치에 
      //버튼이 없는것처럼되서 눌렀는데 안눌려서 동작이 안됨.
      setTimeout(() => {
        setIsKeyboardVisible(false);
      }, 50);
    };
  
    if (inputElement) {
      inputElement.addEventListener('focusin', handleFocusIn);
      inputElement.addEventListener('focusout', handleFocusOut);
    }
  
    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focusin', handleFocusIn);
        inputElement.removeEventListener('focusout', handleFocusOut);
      }
    };
  }, []);

  // 초기 렌더링 시 inputValue의 유효성 검사
  useEffect(() => {
    const koreanAndNumbersOnly = /^[\u3131-\uD79D\u318D\u119E\s0-9]*$/;
    const emojiPattern = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    
    if (inputValue && (!koreanAndNumbersOnly.test(inputValue) || emojiPattern.test(inputValue))) {
      setWarningText('입력할 수 없는 문자입니다.');
    }
  }, []); // 빈 배열을 사용해 첫 렌더링 시 한 번만 실행

  const handleInputChange = (event) => {
    const value = event.target.value;
  
    // 한글 자모 조합 + 추가 유니코드 + 숫자 허용, 이모지 제외
    const koreanAndNumbersOnly = /^[\u3131-\uD79D\u318D\u119E\s0-9]*$/;
  
    // 이모지 전체를 필터링하는 정규식
    const emojiPattern = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  
    // 아래아가 포함되어 있는지 확인하는 정규식
    const araeaPattern = /\u318D/;

    // 괄호로 논리 연산자 우선순위를 명확히 함
    if ((koreanAndNumbersOnly.test(value) && !emojiPattern.test(value) && !araeaPattern.test(value)) || value === '') {
      setInputValue(value);
      setWarningText(''); // 경고 메시지 초기화
    } else {
      setInputValue(value);
      setWarningText('입력할 수 없는 문자입니다.'); // 경고 메시지 설정
    }
  };

  const handleOpenDialog = (title, content, confirmText = '확인') => {
    console.log('handleOpenDialog');
    setDialogProps({
      open: true,
      title,
      content,
      confirmText
    });
  };

  const handleCloseDialog = () => {
    console.log('handleCloseDialog');
    setDialogProps({
      ...dialogProps,
      open: false
    });

    //dialog 가 닫힐때 focus 를 다시 주도록함.
    //ios 에서는 키패드가 올라가는 효과가 발생되게 됨.
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);
  };

  const handleSave = () => {
    console.log('handleSave');
    if (inputValue.trim() === '') {
      handleOpenDialog('경고', '장소 이름을 입력해주세요.');
      return;
    }

    if (warningText) {
      handleOpenDialog('입력 오류', '입력할 수 없는 문자입니다.');
      return;
    }

    if (favorites) {
      // 배열의 각 요소에 대해 공백제거
      const trimmedFavorites = favorites.map((fav) => {
        const result = removeAllSpaces(fav);
        console.log('result :'+ result);
        return result;
      });
      console.log('favorites : ', favorites);
      console.log('trimmedFavorites : ', trimmedFavorites);
      if (trimmedFavorites.includes(removeAllSpaces(inputValue))) {
        handleOpenDialog('알림', '같은 이름이 있어요. 이름을 변경해주세요.');
        return;
      }
    }

    const responseJson = location.state;

    responseJson.originalAddressName = location.state.addressName;
    responseJson.addressName = inputValue;
    responseJson.modifyId = modifyId;

    console.log('save : ', responseJson);

    instanceRef.current.crossFrame.emit('iframe_to_parent', responseJson, function(err, result) {
      console.log('[frame.html] iframe_to_parent callback', 'err:', err, 'result:', result);
    });

    navigate('/end', { replace: true });
    // window.location.replace('/');
  };

  const handleExit = useCallback(() => {
    console.log("handleExit");
    const responseJson = {command: 'exit'};
    instanceRef.current.crossFrame.emit('iframe_to_parent', responseJson, function(err, result) {
      console.log('[frame.html] iframe_to_parent callback', 'err:', err, 'result:', result);
    });
  }, []);

  return (
    <Container disableGutters style={{ paddingTop: '0rem', textAlign: 'left', marginLeft: 0, height: '100vh', }}>
      {/* <BackButton /> */}
      {/* 타이틀 */}
      <Typography variant="h6" gutterBottom width="100%" align="left" 
            sx={{
              fontSize: '17px',
              color: '#606c80'
            }}>
        즐겨 찾는 장소
      </Typography>

      {addressName && <Typography
        sx={{
          fontSize: '16px',
          color: '#111111',
        }}
      >
      {addressName}
      </Typography>}
      {originalAddressName && <Typography
        sx={{
          fontSize: '13px',
          color: '#606c80',
        }}
      >
      {originalAddressName}
      </Typography>}

      <Typography variant="h6" gutterBottom width="100%" align="left" 
            sx={{
              fontSize: '17px',
              color: '#606c80',
              marginTop: '20px'
            }}>
        이름 등록
      </Typography>

      {/* 입력 필드 */}
      <TextField
        variant="outlined"
        fullWidth
        inputRef={inputRef}
        value={inputValue}
        helperText={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span style={{ flex: 1 }}>
              {(warningText || inputValue.length > 10) && (
                <span style={{ color: 'red', backgroundColor: '#eff1f4', padding: '5px' }}>
                  {inputValue.length > 10 ? '글자 수를 줄여주세요.' : warningText}
                </span>
              )}
            </span>
            {inputValue.length > 0 && (
              <span style={{ marginLeft: 'auto', flexShrink: 0 }}>
                <span style={{ color: inputValue.length > 10 ? 'red' : '#606c80' }}>
                  {inputValue.length}
                </span>
                <span style={{ color: '#606c80' }}> / 10</span>
              </span>
            )}
          </div>
        }
        onChange={handleInputChange}
        placeholder='장소 이름을 입력해주세요'
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSave();
          }
        }}
        inputProps={{
          enterKeyHint: 'done',  // 키패드의 Enter 버튼을 "완료"로 표시
        }}
        style={{ marginBottom: '5px',marginTop: '5px' }}
        FormHelperTextProps={{
          sx: {
            color: 'red', // 경고 메시지를 빨간색으로 설정
            backgroundColor:'#eff1f4',
            margin:'0px',
            padding:'5px',
          },
        }}
        sx={{
          backgroundColor: 'transparent', // 입력 필드의 배경을 투명하게 설정하여 문제 해결
          borderRadius: '10px',  // 필드를 둥글게 만듦
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'white', // 입력 필드의 배경을 투명하게 설정하여 문제 해결
            borderRadius: '10px',  // 입력 필드 둥근 모서리 설정
            '& fieldset': {
              borderRadius: '10px',  // 필드셋에 둥근 모서리 적용
              borderColor: 'transparent',  // 테두리 색상 설정
            },
            '&:hover fieldset': {
              borderColor: '#5266f8',  // hover 시 테두리 색상 유지
            },
            '&.Mui-focused fieldset': {
              borderColor: warningText || inputValue.length > 10 ? 'red' : '#5266f8',  // focus 시 테두리 색상 유지
            },
          },
          '& input': {
            padding: '12px 18px',  // 입력창 안쪽 패딩 조정
            height: '26px',  // 입력창 높이 조정
            borderRadius:'16px',
            backgroundColor: 'white', // 입력 필드의 배경을 투명하게 설정하여 문제 해결
          },
          // Chrome 자동 완성 스타일 제거
          '& input:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset !important',  // 자동 완성 배경색 덮어쓰기
            backgroundColor: 'white !important',
            color: '#000 !important',  // 자동 완성된 텍스트 색상을 검정색으로 설정
          },
          '& input:-webkit-autofill:focus': {
            WebkitBoxShadow: '0 0 0 1000px white inset !important',
            backgroundColor: 'white !important',
          },
          '& input:-webkit-autofill:hover': {
            WebkitBoxShadow: '0 0 0 1000px white inset !important',
            backgroundColor: 'white !important',
          },
          '& input:-webkit-autofill:active': {
            WebkitBoxShadow: '0 0 0 1000px white inset !important',
            backgroundColor: 'white !important',
          }
        }}
        InputProps={{
        endAdornment: (
          <>
            {inputValue ? 
              (<InputAdornment position="end">
              <IconButton onClick={() => {
                setInputValue('');
                setWarningText('');
                }} edge="end">
                <img src={ClearIcon} alt='삭제' style={{ width: '24px', height: '24px' }}/>
              </IconButton>
            </InputAdornment> ) : null
            }
          </>
        ),
      }}
      />

      <Typography
        sx={{
          fontSize: '13px',
          color: '#606c80',
        }}
      >
      음성으로 택시를 호출할 때 저장된 이름을 사용해주세요.
      </Typography>

      <Box
        position={isIOS ? "absolute" : "fixed"} // iOS에서 absolute 사용
        bottom={!isIOS ? 0 : isKeyboardVisible && !dialogProps.open ? window.innerHeight > 600 ? 300 : 200 : 0 }
        left={0}
        width="100%"
        sx={{
          boxSizing: 'border-box',
          // backgroundColor: 'white', // 하단 영역이 키패드가 올라와도 명확하게 보이도록 배경 설정
          // boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)', // 살짝 그림자 효과를 추가
          zIndex: 9999, // 다른 요소들보다 위에 위치하도록 설정
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          height={'48px'}
        >
          {/* 취소 버튼 */}
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              console.log('click cancel');
              handleExit();
              }} // 취소 버튼 클릭 시 이전 페이지로 이동
            sx={{
              marginRight: '0.5rem',
              backgroundColor: "#ffffff",
              color: "#23778e",
              borderRadius: "10px",
              fontSize: "17px",
              boxShadow: 'none'
            }}
            disableRipple
          >
            취소
          </Button>

          {/* 저장 버튼 */}
          <Button
            variant="contained"
            fullWidth
            onClick={handleSave}
            disabled={Boolean(warningText) || inputValue.length > 10 || inputValue.length === 0}  // warningText가 있을 때 버튼 비활성화, 10자 초과 또는 0자일 때 비활성화
            sx={{
              backgroundColor: warningText || inputValue.length > 10 || inputValue.length === 0 ? "#c4c4c4" : "#5266f8",  // 비활성화 시 회색
              fontSize: "17px",
              color: warningText || inputValue.length > 10 || inputValue.length === 0 ? "#8e8e8e" : "#eff1f4",  // 비활성화 시 텍스트 색상 조정
              borderRadius: "10px",
              boxShadow: 'none',
              '&.Mui-disabled': {
                backgroundColor: "#c4c4c4",  // 비활성화 시 버튼 배경색 조정
                color: "#8e8e8e",  // 비활성화 시 텍스트 색상 조정
              },
            }}
            disableRipple
          >
            저장
          </Button>
        </Box>
      </Box>
      <CustomDialog
        open={dialogProps.open}
        onClose={handleCloseDialog}
        title={dialogProps.title}
        content={dialogProps.content}
        confirmText={dialogProps.confirmText}
      />
      {/* <AndroidToast /> */} 
    </Container>
  );
};

export default InputName;