import React from 'react';
import { Toaster, toast } from 'react-hot-toast';

export const showAndroidToast = (message) => {
  const toastId = toast.custom((t) => (
    <div
      style={{
        position: 'fixed', // 화면에 고정
        top: '30vh', // 화면의 25% 지점 (vh 단위 사용)
        left: '50%', // 가로 방향 중앙 정렬
        transform: 'translateX(-50%)', // 가로 방향 중앙 정렬을 위한 transform
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px 16px',
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Android Toast의 어두운 배경
        color: 'white',
        borderRadius: '8px', // Android Toast의 둥근 모서리
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)', // 약간의 그림자
        minWidth: '160px', // 적절한 너비 설정
        fontSize: '14px', // 텍스트 크기
        opacity: t.visible ? 1 : 0, // Toast가 사라질 때 애니메이션 적용
        transition: 'opacity 0.3s ease',
      }}
    >
      {t.visible && <span>{message}</span>}
    </div>
  ));

  // 일정 시간이 지난 후 Toast 제거
  setTimeout(() => {
    toast.remove(toastId); // Toast 제거
  }, 3000); // 3초 후에 자동으로 제거
};

const AndroidToast = () => {
  return (
    <div>
      <Toaster />
    </div>
  );
};

export default AndroidToast;